import React from "react";
import { useEffect, useState, useCallback } from "react";
import { Icon, Loader, Dimmer, Modal, Button } from "semantic-ui-react";
import {
  getPortraitPictures,
  passportsSelectPortrait,
} from "../../../../../../../../../../apis/applicantApi";
import { useSelector } from "react-redux";
import toastr from "toastr";
import env from "../../../../../../../../../../env";
import "./PassportPortraitPhoto.scss";  
import apis from "../../../../../../../../../../apis";

const LoadingComponent = () => {
  return (
    <div style={{ width: "100%", position: "relative", height: "120px" }}>
      <Loader size="small" active>
        Loading
      </Loader>
    </div>
  );
};

const PictureModal = ({ action, setOpenModal, openModal, onConfirm }) => {
  const { currentUser } = useSelector((state) => ({
    currentUser: state.currentUser,
  }));

  return (
    <Modal
      onOpen={() => setOpenModal(true)}
      onClose={() => setOpenModal(false)}
      open={openModal}
      size="small"
    >
      <Modal.Header>Confirm your selection</Modal.Header>
      <Modal.Content>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3
            style={{
              width: "100%",
              fontWeight: "bold",
              fontSize: "16pt",
              textAlign: "center",
            }}
          >
            Are you sure want to {action === "select" ? "select" : "deselect"}{" "}
            this photo?
          </h3>
          <p
            style={{
              width: "100%",
              fontSize: "12pt",
              color: "#545454",
              textAlign: "center",
            }}
          >
            Your selection will be able to change later.
          </p>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => setOpenModal(false)}
          inverted={currentUser.settings.activeTheme === "dark"}
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          color="blue"
          inverted={currentUser.settings.activeTheme === "dark"}
        >
          Confirm
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const AlertFileNotFound = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "gray",
        gap: "10px",
      }}
    >
      <Icon name="id badge outline" size="large" />
      <div style={{ textAlign: "center" }}>Invalid/Not found Picture</div>
    </div>
  );
};

const PortraitPicture = ({ portrait, isSelected, onSelect }) => {
  const { id, url, uploadedAt } = portrait;
  const [error, setError] = useState(false);

  const handleClick = () => {
    if (env.PASSPORTS_SELECT_PORTRAIT) {
      onSelect(id);
    }
  };

  const handleImageError = () => {
    setError(true);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "200px",
      }}
      onClick={handleClick}
    >
      <div
        style={{
          border: "1px solid #c2c2c2",
          borderRadius: "10px",
          padding: "20px 10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
        className="portrait-container"
      >
        {!error ? (
          <img
            style={{ width: "100%" }}
            src={url}
            alt={`Invalid/Not found Picture passport-${id}`}
            onError={handleImageError}
          />
        ) : (
          <AlertFileNotFound />
        )}
        {isSelected ? (
          <span
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              borderRadius: "10px",
              padding: "5px",
            }}
          >
            <Icon name="check circle" size="huge" color="green" />
          </span>
        ) : null}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "start",
          gap: "2px",
          fontWeight: "bold",
          fontSize: "10pt",
          textOverflow: "clip",
        }}
      >
        <span>Uploaded on: {uploadedAt}</span>
      </div>
    </div>
  );
};

const PassportPortraitPhoto = ({ id, api, applicant }) => {
  const [isPortraits, setIsPortraits] = useState(true);
  const [portraits, setPortraits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [action, setAction] = useState("select"); 
  const [requestLoading, setRequestLoading] = useState(false);

  const handleSelect = (id) => {
    if (selectedId === id) {
      setOpenModal(true);
      setAction("deselect");
      return;
    }
    setSelectedId(id);
    setAction("select");
    setOpenModal(true);
  };

  const getPortraits = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getPortraitPictures(id, api);
      if (!data.portraitPictures) {
        setPortraits([]);
        setIsPortraits(false);
        setLoading(false);
        return;
      }

      setPortraits(data.portraitPictures);
      setIsPortraits(true);
      setLoading(false);
      return;
    } catch (error) {
      console.log(error);
      setIsPortraits(false);
      setLoading(false);
    }
  }, [id, api]);

  const handleConfirmSelectPhoto = async (
    applicantId,
    portraitId,
    select = true,
  ) => {
    const { data } = await passportsSelectPortrait(applicantId, api, {
      portraitId,
      select,
    });

    if (data.error) {
      console.log("[ERROR] Failed to save the selection", data.error);
      return;
    }
    setSelectedId(data.portraitId);
    setOpenModal(false);
    applicant.selectedPicture = data.portraitId;
    toastr.success(select ? "Photo Selected" : "Photo Unselected");
  };

  const isSelected = (portrait) => {
    return portrait.id === selectedId;
  };

  const handleRequestPortrait = async (applicantId) => { 
    if (requestLoading || !applicantId) return;
    
    setRequestLoading(true);
    try {
      await apis.applicantDocumentHandler.sendDocumentRequest(api, applicantId, ['portraitPicture']);
      toastr.success("Portrait photo request sent successfully.");
    } catch (error) {
      console.error("Failed to send portrait photo request", error);
      toastr.error("Failed to send portrait photo request.");
    } finally {
      setRequestLoading(false);
    }
  };

  useEffect(() => {
    if (applicant.selectedPicture) {
      setSelectedId(applicant.selectedPicture);
    }
  }, [applicant]);

  useEffect(() => {
    getPortraits();
  }, [getPortraits]);

  if (loading) return <LoadingComponent />;

  return (
    <>
      <PictureModal
        action={action}
        openModal={openModal}
        setOpenModal={setOpenModal}
        setSelectedId={setSelectedId}
        onConfirm={() => {
          handleConfirmSelectPhoto(id, selectedId, action === "select");
        }}
      />
      <div style={{ width: "100%", padding: "12px 0px" }}>
        <h3 style={{ color: "#545454" }}>Passport Portrait Photos</h3>
        {portraits && portraits.length > 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
              flexWrap: "wrap",
            }}
          >
            {portraits.map((portrait, index) => (
              <PortraitPicture
                key={`portrait-${index}-${portrait.id}`}
                portrait={portrait}
                isSelected={isSelected(portrait)}
                onSelect={handleSelect}
              />
            ))}
          </div>
        ) : (
          <div style={{ marginTop: "20px" }}>
            <p>No portrait photos available.</p>
            <Button 
              color="blue" 
              onClick={() => handleRequestPortrait(id)}
              loading={requestLoading}
              disabled={requestLoading}
            >
              Request Portrait Photo
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default PassportPortraitPhoto;
