import React from "react";
import { Button } from "semantic-ui-react";
import { FORM_SCHEMA_TYPES as TYPES } from "../formSchemaTypes";
import { TRAVELASSIST_ZENDESK_TICKET_ENDPOINT } from "../../../../../../../../constants/endpoints";
import ZendeskIcon from "../../../../../../../../components/Icons/ZendeskIcon/ZendeskIcon";
import { get } from "lodash";

export const DS11_SELECT_VALUES = {
  GENDERS: "GENDERS",
  COUNTRIES: "COUNTRIES",
  HAIR_COLORS: "HAIR_COLORS",
  EYE_COLORS: "EYE_COLORS",
  COMMUNICATION_METHODS: "COMMUNICATION_METHODS",
  PHONE_COUNTRY_CODES: "PHONE_COUNTRY_CODES",
  PHONE_TYPES: "PHONE_TYPES",
  PASSPORT_STATUS: "PASSPORT_STATUS",
  AMERICAN_STATES: "AMERICAN_STATES",
  PASSPORT_TYPES: "PASSPORT_TYPES",
  ACCEPTANCE_FACILITY: "ACCEPTANCE_FACILITY",
  MARITAL_STATUS: "MARITAL_STATUS",
  PARENTAL_RELATIONSHIP: "PARENTAL_RELATIONSHIP",
};

const isEmpty = (value) => value === undefined;
const invalidValues = [undefined, "", null];

const isVersion1 = (applicant) => {
  // birthPlace is a new required field in version 2.
  const birthPlace = get(applicant, "birthPlace");
  return isEmpty(birthPlace);
};

const isBirthPlaceValid = (applicant) => {
  const birthStateProvince = get(applicant, "birthStateProvince");
  const birthCity = get(applicant, "birthCity");
  const birthPlace = get(applicant, "birthPlace");
  const isStateAndCity =
    !invalidValues.includes(birthCity) &&
    !invalidValues.includes(birthStateProvince);
  return !isStateAndCity && !invalidValues.includes(birthPlace);
};

const AddressSchema = (path) => ({
  streetAddress: {
    label: "Street Address",
    type: TYPES.TEXT,
    path,
  },
  streetAddress2: {
    label: "Street Address 2",
    type: TYPES.TEXT,
    path,
  },
  country: {
    label: "Country",
    type: TYPES.SELECT,
    values: DS11_SELECT_VALUES.COUNTRIES,
    path,
  },
  state: {
    label: "State",
    type: TYPES.SELECT,
    values: DS11_SELECT_VALUES.AMERICAN_STATES,
    path,
    customDependency: (applicant, path, schemaData) => {
      const country = get(applicant, `${path}.country`);
      return country === "USA";
    },
  },
  city: {
    label: "City",
    type: TYPES.TEXT,
    path,
  },
  apartmentUnit: {
    label: "Apartment/Unit",
    type: TYPES.TEXT,
    path,
    customDependency: (applicant, path, schemaData) => {
      const apartmentUnit = get(applicant, `${path}.apartmentUnit`);
      return !!apartmentUnit;
    },
  },
  apartmentOrUnit: {
    label: "Apartment/Unit",
    type: TYPES.TEXT,
    path,
    customDependency: (applicant, path) => {
      const apartmentUnit = get(applicant, `${path}.apartmentOrUnit`);
      return !!apartmentUnit;
    },
  },
  zipCode: {
    label: "Zip Code",
    type: TYPES.TEXT,
    path,
  },
});

const PhoneNumberSchema = (path) => ({
  countryCode: {
    label: "Country code",
    type: TYPES.SELECT,
    values: DS11_SELECT_VALUES.PHONE_COUNTRY_CODES,
    path,
  },
  number: {
    label: "Number",
    type: TYPES.TEXT,
    path,
  },
  label: {
    label: "Phone number type",
    type: TYPES.SELECT,
    values: DS11_SELECT_VALUES.PHONE_TYPES,
    customDependency: () => {
      const pathParts = path.split(".");
      // For Arrays. 'phoneNumber' needs to be last part of the path. Example: "additionalPhoneNumbers.$additionalPhoneNumbers.phoneNumber"
      const isAdditionalPhone =
        pathParts[pathParts.length - 1] === "phoneNumber";
      return !isAdditionalPhone;
    },
  },
});

const PassportBookInfoSchema = (path) => ({
  everApplied: {
    label: `Have you ever applied for or been issued a Passport ${
      path === "passportBookInfo" ? "Book" : "Card"
    }?`,
    type: TYPES.CHECKBOX,
    path,
  },
  name: {
    label: "Full name",
    type: TYPES.TEXT,
    path,
    customDependency: (applicant) => {
      const everApplied = get(applicant, `${path}.everApplied`);
      if (everApplied === undefined) return true;
      else return everApplied;
    },
  },
  number: {
    label: `Most recent passport ${
      path === "passportBookInfo" ? "book" : "card"
    } number`,
    type: TYPES.TEXT,
    path,
    customDependency: (applicant) => {
      const everApplied = get(applicant, `${path}.everApplied`);
      if (everApplied === undefined) return true;
      else return everApplied;
    },
  },
  issueDate: {
    label: "Issue Date",
    type: TYPES.DATE,
    path,
    customDependency: (applicant) => {
      const everApplied = get(applicant, `${path}.everApplied`);
      if (everApplied === undefined) return true;
      else return everApplied;
    },
  },
  expDate: {
    label: "Expiration date",
    type: TYPES.DATE,
    path,
    customDependency: (applicant) => {
      const everApplied = get(applicant, `${path}.everApplied`);
      if (everApplied === undefined) return true;
      else return everApplied;
    },
  },
  status: {
    label: "Status",
    type: TYPES.SELECT,
    values: DS11_SELECT_VALUES.PASSPORT_STATUS,
    path,
    customDependency: (applicant) => {
      const everApplied = get(applicant, `${path}.everApplied`);
      if (everApplied === undefined) return true;
      else return everApplied;
    },
  },
});

export const ds11Schema = {
  generalInfoSegment: {
    label: "General application information",
    type: TYPES.SEGMENT,
    initialOpen: true,
    data: {
      _id: {
        label: "ID",
      },
      applicationType: {
        label: "Application Type",
      },
      mainApplicationId: {
        label: "Main Application ID",
        dependsOn: "applicationType",
        dependantOnValues: ["Secondary"],
      },
      siteId: {
        label: "Referral Site",
      },
      cartSiteId: {
        label: "Purchased from website",
      },
      orderId: {
        label: "Order ID",
      },
      orderIdHistory: {
        label: "Order ID history",
      },
      invoiceEmail: {
        label: "Payment email",
      },
      zendeskId: {
        label: "Zendesk ID",
        type: TYPES.TEXT,
        required: false,
        renderIcon: (propValue) => (
          <Button
            onClick={() => {
              window.open(
                TRAVELASSIST_ZENDESK_TICKET_ENDPOINT(propValue),
                "_blank",
              );
            }}
            style={{ width: 36, height: 36, padding: 0, float: "right" }}
            disabled={!propValue}
          >
            <ZendeskIcon className="zendesk-icon" />
          </Button>
        ),
      },
      submissionEmail: {
        label: "Submission email",
        type: TYPES.TEXT,
      },
      submissionPassword: {
        label: "Submission password",
        type: TYPES.TEXT,
      },
      gclid: {
        label: "Google client ID",
      },
      userLanguage: {
        label: "User form language",
      },
      userCountry: {
        label: "Users IP country",
      },
      salesAgent: {
        label: "Sales Agent",
      },
      salesCoupon: {
        label: "Sales Coupon",
      },
    },
  },
  personalInfoSegment: {
    label: "Personal information",
    type: TYPES.SEGMENT,
    data: {
      userEmail: {
        label: "E-mail address",
        type: TYPES.TEXT,
      },
      firstName: {
        label: "First name",
        type: TYPES.TEXT,
      },
      lastName: {
        label: "Last name",
        type: TYPES.TEXT,
      },
      middleName: {
        label: "Middle name",
        type: TYPES.TEXT,
      },
      USSocialSecurityNumber: {
        label: "Social Security Number",
        type: TYPES.TEXT,
      },
      gender: {
        label: "Gender",
        type: TYPES.SELECT,
        values: DS11_SELECT_VALUES.GENDERS,
      },
      isChangingGender: {
        label: "Changing gender marker?",
        type: TYPES.CHECKBOX,
      },
      isDifferentNameAtBirth: {
        label: "Different name at birth",
        type: TYPES.CHECKBOX,
      },
      additionalNames: {
        label: "Additional names",
        type: TYPES.MULTI_TEXT,
        dependsOn: "isDifferentNameAtBirth",
        dependantOnValues: [true],
      },
      additionalFullNames: {
        label: "Additional full names",
        newTable: true,
        type: TYPES.ARRAY,
        dependsOn: "isDifferentNameAtBirth",
        data: {
          firstName: {
            label: "First Name",
            type: TYPES.TEXT,
            path: "additionalFullNames.$additionalFullNames",
          },
          lastName: {
            label: "Last Name",
            type: TYPES.TEXT,
            path: "additionalFullNames.$additionalFullNames",
          },
        },
        customDependency: (applicant) => {
          const additionalFullNames = get(applicant, "additionalFullNames");
          const isDifferentNameAtBirth = get(
            applicant,
            "isDifferentNameAtBirth",
          );

          return (
            isDifferentNameAtBirth &&
            additionalFullNames !== undefined &&
            additionalFullNames.length > 0
          );
        },
      },
      birthDate: {
        label: "Date of birth",
        type: TYPES.DATE,
      },
      birthCountry: {
        label: "Country of birth",
        type: TYPES.SELECT,
        values: DS11_SELECT_VALUES.COUNTRIES,
      },
      birthStateProvince: {
        label: "State/Province of birth",
        type: TYPES.TEXT,
      },
      birthCity: {
        label: "City of birth",
        type: TYPES.TEXT,
      },
      birthPlace: {
        label: "Place of birth",
        type: TYPES.TEXT,
        customDependency: (applicant) => isBirthPlaceValid(applicant),
      },
      isUSCitizen: {
        label: "Are you a US citizen?",
        type: TYPES.CHECKBOX,
      },
      largePassportBookNeeded: {
        label: "Do you need a Large Passport Book?",
        type: TYPES.CHECKBOX,
      },
      height: {
        label: "Height",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          feets: {
            label: "Feet",
            type: TYPES.NUMBER,
            path: "height",
          },
          inches: {
            label: "Inches",
            type: TYPES.NUMBER,
            path: "height",
          },
        },
      },
      hairColor: {
        label: "Hair color",
        type: TYPES.SELECT,
        values: DS11_SELECT_VALUES.HAIR_COLORS,
      },
      eyeColor: {
        label: "Eye color",
        type: TYPES.SELECT,
        values: DS11_SELECT_VALUES.EYE_COLORS,
      },
      permanentAddress: {
        label: "Permanent Address",
        type: TYPES.NESTED,
        newTable: true,
        nested: AddressSchema("permanentAddress"),
      },
      isPermanentAddressSameAsMailing: {
        label: "Is your home address the same as the mailing address?",
        type: TYPES.CHECKBOX,
      },
      mailingAddress: {
        label: "Mailing Address",
        type: TYPES.NESTED,
        newTable: true,
        nested: AddressSchema("mailingAddress"),
      },
      isChildPassport: {
        label: "Passport For a Child",
        type: TYPES.CHECKBOX,
      },
      inCareOf: {
        label: "In Care Of",
        type: TYPES.TEXT,
        dependsOn: "isChildPassport",
      },
      phoneNumber: {
        label: "Primary Contact Number",
        type: TYPES.NESTED,
        newTable: true,
        nested: PhoneNumberSchema("phoneNumber"),
      },
      additionalPhoneNumbers: {
        label: "Additional phone numbers",
        type: TYPES.ARRAY,
        data: {
          phoneNumber: {
            label: "Phone number",
            type: TYPES.NESTED,
            newTable: true,
            nested: PhoneNumberSchema(
              "additionalPhoneNumbers.$additionalPhoneNumbers.phoneNumber",
            ),
          },
          label: {
            label: "Phone label",
            type: TYPES.SELECT,
            values: DS11_SELECT_VALUES.PHONE_TYPES,
            path: "additionalPhoneNumbers.$additionalPhoneNumbers",
          },
        },
      },
      occupation: {
        label: "Occupation",
        type: TYPES.TEXT,
      },
      employeerOrSchool: {
        label: "Employer or School name",
        type: TYPES.TEXT,
      },
    },
  },
  contactInformation: {
    label: "Contact information",
    type: TYPES.SEGMENT,
    data: {
      parentalAuthorityInfo: {
        label: "Parental authority information",
        type: TYPES.ARRAY,
        data: {
          isUnknown: {
            label: "Unknown",
            type: TYPES.CHECKBOX,
            path: "parentalAuthorityInfo.$parentalAuthorityInfo",
          },
          fullName: {
            label: "Full name",
            type: TYPES.TEXT,
            dependsOn: "parentalAuthorityInfo.$parentalAuthorityInfo.isUnknown",
            dependantOnValues: [false],
            path: "parentalAuthorityInfo.$parentalAuthorityInfo",
          },
          firstName: {
            label: "First name",
            type: TYPES.TEXT,
            path: "parentalAuthorityInfo.$parentalAuthorityInfo",
            dependsOn: "parentalAuthorityInfo.$parentalAuthorityInfo.isUnknown",
            dependantOnValues: [false],
            customDependency: (applicant) => {
              const fullName = get(
                applicant,
                "parentalAuthorityInfo.$parentalAuthorityInfo.fullName",
              );
              const isUnknown = get(
                applicant,
                "parentalAuthorityInfo.$parentalAuthorityInfo.isUnknown",
              );
              return isEmpty(fullName) && !isUnknown;
            },
          },
          lastName: {
            label: "Last name",
            type: TYPES.TEXT,
            path: "parentalAuthorityInfo.$parentalAuthorityInfo",
            dependsOn: "parentalAuthorityInfo.$parentalAuthorityInfo.isUnknown",
            dependantOnValues: [false],
            customDependency: (applicant) => {
              const fullName = get(
                applicant,
                "parentalAuthorityInfo.$parentalAuthorityInfo.fullName",
              );
              const isUnknown = get(
                applicant,
                "parentalAuthorityInfo.$parentalAuthorityInfo.isUnknown",
              );
              return isEmpty(fullName) && !isUnknown;
            },
          },
          middleName: {
            label: "Middle name",
            type: TYPES.TEXT,
            path: "parentalAuthorityInfo.$parentalAuthorityInfo",
            dependsOn: "parentalAuthorityInfo.$parentalAuthorityInfo.isUnknown",
            dependantOnValues: [false],
            customDependency: (applicant) => {
              const fullName = get(
                applicant,
                "parentalAuthorityInfo.$parentalAuthorityInfo.fullName",
              );
              const isUnknown = get(
                applicant,
                "parentalAuthorityInfo.$parentalAuthorityInfo.isUnknown",
              );
              return isEmpty(fullName) && !isUnknown;
            },
          },
          birthDate: {
            label: "Date of birth",
            type: TYPES.DATE,
            path: "parentalAuthorityInfo.$parentalAuthorityInfo",
            dependsOn: "parentalAuthorityInfo.$parentalAuthorityInfo.isUnknown",
            dependantOnValues: [false],
          },
          gender: {
            label: "Gender",
            type: TYPES.SELECT,
            values: DS11_SELECT_VALUES.GENDERS,
            path: "parentalAuthorityInfo.$parentalAuthorityInfo",
            dependsOn: "parentalAuthorityInfo.$parentalAuthorityInfo.isUnknown",
            dependantOnValues: [false],
          },
          birthCountry: {
            label: "Country of birth",
            type: TYPES.SELECT,
            values: DS11_SELECT_VALUES.COUNTRIES,
            path: "parentalAuthorityInfo.$parentalAuthorityInfo",
            dependsOn: "parentalAuthorityInfo.$parentalAuthorityInfo.isUnknown",
            dependantOnValues: [false],
            customDependency: (applicant) => {
              const birthPlace = get(
                applicant,
                "parentalAuthorityInfo.$parentalAuthorityInfo.birthPlace",
              );
              return isEmpty(birthPlace);
            },
          },
          birthStateProvince: {
            label: "State/Province of birth",
            type: TYPES.SELECT,
            values: DS11_SELECT_VALUES.AMERICAN_STATES,
            dependsOn: "parentalAuthorityInfo.$parentalAuthorityInfo.isUnknown",
            dependantOnValues: [false],
            customDependency: (applicant, path, schemaData) => {
              const birthPlace = get(
                applicant,
                "parentalAuthorityInfo.$parentalAuthorityInfo.birthPlace",
              );
              const country = get(applicant, `${path}.birthCountry`);
              return country === "USA" && isEmpty(birthPlace);
            },
            path: "parentalAuthorityInfo.$parentalAuthorityInfo",
          },
          birthCity: {
            label: "City of birth",
            type: TYPES.TEXT,
            path: "parentalAuthorityInfo.$parentalAuthorityInfo",
            dependsOn: "parentalAuthorityInfo.$parentalAuthorityInfo.isUnknown",
            dependantOnValues: [false],
            customDependency: (applicant) => {
              const birthPlace = get(
                applicant,
                "parentalAuthorityInfo.$parentalAuthorityInfo.birthPlace",
              );
              return isEmpty(birthPlace);
            },
          },
          birthPlace: {
            label: "City and State",
            type: TYPES.TEXT,
            path: "parentalAuthorityInfo.$parentalAuthorityInfo",
            dependsOn: "parentalAuthorityInfo.$parentalAuthorityInfo.isUnknown",
            dependantOnValues: [false],
          },
          isUSCitizen: {
            label: "Parent is a US citizen?",
            type: TYPES.CHECKBOX,
            path: "parentalAuthorityInfo.$parentalAuthorityInfo",
            dependsOn: "parentalAuthorityInfo.$parentalAuthorityInfo.isUnknown",
            dependantOnValues: [false],
          },
          phoneNumber: {
            label: "Phone Number",
            type: TYPES.NESTED,
            path: "parentalAuthorityInfo.$parentalAuthorityInfo",
            dependsOn: "parentalAuthorityInfo.$parentalAuthorityInfo.isUnknown",
            dependantOnValues: [false],
            newTable: true,
            nested: PhoneNumberSchema(
              "parentalAuthorityInfo.$parentalAuthorityInfo.phoneNumber",
            ),
          },
          relationship: {
            label: "Relationship",
            type: TYPES.SELECT,
            path: "parentalAuthorityInfo.$parentalAuthorityInfo",
            dependsOn: "parentalAuthorityInfo.$parentalAuthorityInfo.isUnknown",
            dependantOnValues: [false],
            values: DS11_SELECT_VALUES.PARENTAL_RELATIONSHIP,
          },
          address: {
            label: "Address",
            type: TYPES.NESTED,
            newTable: true,
            path: "parentalAuthorityInfo.$parentalAuthorityInfo",
            dependsOn: "parentalAuthorityInfo.$parentalAuthorityInfo.isUnknown",
            dependantOnValues: [false],
            nested: AddressSchema(
              "parentalAuthorityInfo.$parentalAuthorityInfo.address",
            ),
          },
        },
      },
      maritalStatus: {
        label: "Marital Status",
        type: TYPES.SELECT,
        values: DS11_SELECT_VALUES.MARITAL_STATUS,
      },
      everMarried: {
        label: "Have you ever been married?",
        type: TYPES.CHECKBOX,
      },
      maritalInfo: {
        label: "Marital information",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "maritalStatus",
        dependantOnValues: ["married"],
        nested: {
          fullName: {
            label: "Full name",
            type: TYPES.TEXT,
            path: "maritalInfo",
          },
          marriageDate: {
            label: "Date of marriage",
            type: TYPES.DATE,
            path: "maritalInfo",
          },
          birthDate: {
            label: "Date of birth",
            type: TYPES.DATE,
            path: "maritalInfo",
          },
          gender: {
            label: "Gender",
            type: TYPES.SELECT,
            values: DS11_SELECT_VALUES.GENDERS,
            path: "maritalInfo",
          },
          placeOfBirth: {
            label: "Place of birth",
            type: TYPES.TEXT,
            path: "maritalInfo",
          },
          everDivorcedWidowed: {
            label: "Ever divorced or widowed?",
            type: TYPES.CHECKBOX,
            path: "maritalInfo",
          },
          divorcedWidowedDate: {
            label: "Date of divorce/widowed",
            type: TYPES.DATE,
            path: "maritalInfo",
            dependsOn: "maritalInfo.everDivorcedWidowed",
          },
          isUSCitizen: {
            label: "Spouse is a US citizen?",
            type: TYPES.CHECKBOX,
            path: "maritalInfo",
          },
          phoneNumber: {
            label: "Phone Number",
            type: TYPES.NESTED,
            newTable: true,
            nested: PhoneNumberSchema("maritalInfo.phoneNumber"),
            path: "maritalInfo",
          },
          address: {
            label: "Address",
            type: TYPES.NESTED,
            newTable: true,
            nested: AddressSchema("maritalInfo.address"),
            path: "maritalInfo",
          },
        },
      },
      divorceInfo: {
        label: "Divorce Information",
        type: TYPES.NESTED,
        newTable: true,
        path: "divorceInfo",
        dependsOn: "maritalStatus",
        dependantOnValues: ["divorced"],
        nested: {
          fullName: {
            label: "Full Name",
            type: TYPES.TEXT,
            path: "divorceInfo",
          },
          divorceDate: {
            label: "Divorce Date",
            type: TYPES.DATE,
            path: "divorceInfo",
          },
          marriageDate: {
            label: "Date of marriage",
            type: TYPES.DATE,
            path: "divorceInfo",
          },
          gender: {
            label: "Gender",
            type: TYPES.SELECT,
            values: DS11_SELECT_VALUES.GENDERS,
            path: "divorceInfo",
          },
          birthDate: {
            label: "Date of birth",
            type: TYPES.DATE,
            path: "divorceInfo",
          },
          placeOfBirth: {
            label: "Place of birth",
            type: TYPES.TEXT,
            path: "divorceInfo",
          },
          isUSCitizen: {
            label: "Spouse is a US citizen?",
            type: TYPES.CHECKBOX,
            path: "divorceInfo",
          },
        },
      },
      widowInfo: {
        label: "Widow Information",
        type: TYPES.NESTED,
        newTable: true,
        path: "widowInfo",
        dependsOn: "maritalStatus",
        dependantOnValues: ["widowed"],
        nested: {
          fullName: {
            label: "Full Name",
            type: TYPES.TEXT,
            path: "widowInfo",
          },
          widowDate: {
            label: "Widow Date",
            type: TYPES.DATE,
            path: "widowInfo",
          },
          marriageDate: {
            label: "Date of marriage",
            type: TYPES.DATE,
            path: "widowInfo",
          },
          gender: {
            label: "Gender",
            type: TYPES.SELECT,
            values: DS11_SELECT_VALUES.GENDERS,
            path: "widowInfo",
          },
          birthDate: {
            label: "Date of birth",
            type: TYPES.DATE,
            path: "widowInfo",
          },
          placeOfBirth: {
            label: "Place of birth",
            type: TYPES.TEXT,
            path: "widowInfo",
          },
          isUSCitizen: {
            label: "Spouse is a US citizen?",
            type: TYPES.CHECKBOX,
            path: "widowInfo",
          },
        },
      },
      emergencyContact: {
        label: "Emergency contact",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          name: {
            label: "Full name",
            type: TYPES.TEXT,
            path: "emergencyContact",
          },
          relationship: {
            label: "Relationship to the Applicant",
            type: TYPES.TEXT,
            path: "emergencyContact",
          },
          phoneNumber: {
            label: "Primary Contact Number",
            type: TYPES.NESTED,
            newTable: true,
            nested: PhoneNumberSchema("emergencyContact.phoneNumber"),
          },
          address: {
            label: "Address",
            type: TYPES.NESTED,
            newTable: true,
            nested: AddressSchema("emergencyContact.address"),
          },
        },
      },
      selectOtherContactInfo: {
        label: "Select From Other Contact Information",
        type: TYPES.CHECKBOX,
      },
    },
  },
  passportInformation: {
    label: "Passport Information",
    type: TYPES.SEGMENT,
    data: {
      trackingNumberHistory: {
        label: "Tracking Number History",
      },
      everAppliedForUSPassport: {
        label: "Ever applied for U.S. passport?",
        type: TYPES.CHECKBOX,
        customDependency: (applicant) => {
          const isBookEverAppliedProp = get(
            applicant,
            "passportBookInfo.everApplied",
          );
          const isCardEverAppliedProp = get(
            applicant,
            "passportBookInfo.everApplied",
          );
          return (
            isBookEverAppliedProp === undefined &&
            isCardEverAppliedProp === undefined
          );
        },
      },
      passportType: {
        label: "Passport type",
        type: TYPES.SELECT,
        values: DS11_SELECT_VALUES.PASSPORT_TYPES,
        dependsOn: "everAppliedForUSPassport",
      },
      passportBookInfo: {
        label: "Passport book information",
        type: TYPES.NESTED,
        newTable: true,
        nested: PassportBookInfoSchema("passportBookInfo"),
      },
      passportCardInfo: {
        label: "Passport card information",
        type: TYPES.NESTED,
        newTable: true,
        nested: PassportBookInfoSchema("passportCardInfo"),
      },
      travelPlanned: {
        label: "Do you have any travel plans?",
        type: TYPES.CHECKBOX,
      },
      travelPlans: {
        label: "Travel plans",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          departureDate: {
            label: "Departure date",
            type: TYPES.DATE,
            path: "travelPlans",
          },
          returnDate: {
            label: "Return date",
            type: TYPES.DATE,
            path: "travelPlans",
          },
          countriesToVisitList: {
            label: "Countries to visit",
            type: TYPES.MULTI_TEXT,
            path: "travelPlans",
            values: DS11_SELECT_VALUES.COUNTRIES,
          },
          countriesToVisit: {
            label: "Countries to visit",
            type: TYPES.TEXT,
            path: "travelPlans",
            customDependency: (applicant) => {
              return isVersion1(applicant);
            },
          },
        },
        dependsOn: "travelPlanned",
        dependantOnValues: [true],
      },
      documentTypeForPDF: {
        label: "Document type for PDF",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          book: {
            label: "Passport book",
            type: TYPES.CHECKBOX,
            path: "documentTypeForPDF",
          },
          card: {
            label: "Passport card",
            type: TYPES.CHECKBOX,
            path: "documentTypeForPDF",
          },
        },
      },
    },
  },
};
