export const APPLICANT_STATUSES = {
  New: 0,
  "Pending submission": 1,
  "Bot in progress": 2,
  "Bot returned error": 3,
  Submitted: 4,
  "Submitted and paid": 5,
  "Waiting for approval": 6,
  Approved: 7,
  Rejected: 8,
  "Email parse failed": 9,
  "More info required": 10,
  Escalated: 11,
  Refunded: 12,
  Unpaid: 13,
  "Status checker error": 14,
  "Passport already exists, paid application": 15,
  Cancelled: 17,
  "Processed manually": 18,
  "Embassy account created": 19,
  Chargeback: 20,
  "Interview Scheduled": 21,
  "VISA Approved": 22,
  "VISA Issued": 23,
  "Passport received": 24,
  Scheduled: 25,
  Active: 26,
  Completed: 27,
  "Bot in queue": 28,
  "Payment failed - will retry payment": 29,
  "Payment could not be determined": 30,
  "Extended Support": 31,
  "Embassy bot in progress": 32,
  "Embassy bot returned error": 33,
  "Embassy bot in queue": 34,
  "Personal embassy account": 35,
  "Biometrical appointment": 36,
  "Interview waiver": 37,
  "Visa rejected": 38,
  "Drop off Appointment": 39,
  "Follow-up Appointment": 40,
  "Passport in Transit": 41,
  "Passport already exists, failed retrieving old application": 42,
  "Failed retrying payment by autopilot": 43,
  "Retrying payment": 44,
  "Administrative Processing": 45,
  "Previously submitted application. Travel not authorized": 46,
  "Canceled Authorization": 47,
  "Partially Refunded": 48,
  "Customer data received": 49,
  "Submitted to IVISA": 50,
  "Payment failed": 51,
  "Applicant has valid NZeTA, proceed with override if needed": 52,
  "Appointment Scheduled": 53,
  "Payment bot in queue": 54,
  "Payment bot in progress": 55,
  "Service error": 56,
  "Invalid passport image": 57,
  Delivered: 62,
  "Documents mailed": 63,
  "Ready for PKO": 64,
  "Photo not uploaded": 65,
  "Photo accepted": 66,
  "Photo invalid": 67,
  "Kit printed": 68,
  "Kit mailed": 69,
  "Agency: QuickPassport": 70,
  "Draft created": 71,
  "Draft sent to client": 72,
  "Draft confirmed by client": 73,
};

export const embassyDocumentsEligibleStatuses = [
  APPLICANT_STATUSES["Administrative Processing"],
  APPLICANT_STATUSES["Interview Scheduled"],
  APPLICANT_STATUSES["Biometrical appointment"],
  APPLICANT_STATUSES["Drop off Appointment"],
  APPLICANT_STATUSES["Follow-up Appointment"],
  APPLICANT_STATUSES["Interview waiver"],
  APPLICANT_STATUSES["VISA Approved"],
  APPLICANT_STATUSES["Visa rejected"],
  APPLICANT_STATUSES["Passport in Transit"],
];

export const PAYMENT_STATUSES = {
  Unpaid: 0,
  Authorized: 8,
  Captured: 1,
  "Partially captured": 5,
  Canceled: 7,
  Refunded: 3,
  "Partially refunded": 2,
  Chargeback: 4,
};

export const WARNING_PAYMENT_STATUSES = [
  PAYMENT_STATUSES.Chargeback,
  PAYMENT_STATUSES.Refunded,
  PAYMENT_STATUSES.Canceled,
  PAYMENT_STATUSES["Partially refunded"],
];

export const WARNING_STATUSES = [
  APPLICANT_STATUSES.Chargeback,
  APPLICANT_STATUSES.Refunded,
  APPLICANT_STATUSES["Partially Refunded"],
  APPLICANT_STATUSES["Canceled Authorization"],
];

export const getWarningPaymentStatuses = () => {
  return WARNING_PAYMENT_STATUSES.map((status) =>
    getPaymentStatusString(status),
  );
};

export const getWarningStatuses = () => {
  return WARNING_STATUSES.map((status) => getApplicantStatusString(status));
};

export const ZENDESK_VIEW_PAYMENT_STATUSES = {
  All: [],
  ...PAYMENT_STATUSES,
};

/**
 *
 * @param {string|string[]} status
 * @returns {string|string[]}
 */
export function getApplicantStatusString(status) {
  if (Array.isArray(status)) {
    return status.map((statusEl) => getApplicantStatusString(statusEl));
  }
  if (typeof status === "number") {
    const [stringStatus] = Object.entries(APPLICANT_STATUSES).find(
      ([, number]) => number === status,
    );
    return stringStatus;
  }
  return status;
}

/**
 *
 * @param {string|string[]} status
 * @returns {string|string[]}
 */
export function getPaymentStatusString(status) {
  if (Array.isArray(status)) {
    return status.map((statusEl) => getPaymentStatusString(statusEl));
  }
  if (typeof status === "number") {
    const [stringStatus] = Object.entries(PAYMENT_STATUSES).find(
      ([, number]) => number === status,
    );
    return stringStatus;
  }
  return status;
}
