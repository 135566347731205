import { UspsRouter, K1Router } from "./EVisa";
import { API_NAMES } from "./EVisa/constants/apiNames";
import VisaApplicantsTable from "./EVisa/pages/VisaApplicantsTable";
import ApplicantEdit from "./EVisa/pages/ApplicantsEdit";
import ApplicantDocumentHandler from "./EVisa/pages/ApplicantDocumentHandler";
import routeConfig from "../../../../modules/routeConfig";
import { PRODUCT_NAMES } from "./constants/productNames";
import PassportApplicantTable from "./EVisa/pages/VisaApplicantsTable/passport-table";
import { ROLES } from "../../../../../constants/rolesConstants";

const routes = [
  {
    path: "/k1_visa",
    component: K1Router,
    exact: false,
    product: PRODUCT_NAMES.K1,
  },
  {
    path: "/usps",
    component: UspsRouter,
    exact: false,
    product: PRODUCT_NAMES.USPS,
  },
  {
    path: "/ds11/ready-for-pko",
    component: PassportApplicantTable,
    product: PRODUCT_NAMES.DS11,
    componentProps: { api: PRODUCT_NAMES.DS11 },
    authorizedRoles: [ROLES.ADMIN, ROLES.PKO_OFFICE],
  },
  {
    path: "/ds82/ready-for-pko",
    component: PassportApplicantTable,
    product: PRODUCT_NAMES.DS82,
    componentProps: { api: PRODUCT_NAMES.DS82 },
    authorizedRoles: [ROLES.ADMIN, ROLES.PKO_OFFICE],
  },
  ...Object.values(API_NAMES).map((api) => ({
    component: VisaApplicantsTable,
    path: `/${api}`,
    componentProps: { api },
    product: api,
  })),
  ...Object.values(API_NAMES).map((api) => ({
    path: `/${api}/applicant/:id`,
    component: ApplicantEdit,
    componentProps: { api },
    product: api,
  })),
  ...Object.values(API_NAMES).map((api) => ({
    path: `/${api}/applicant/:id/documents`,
    component: ApplicantDocumentHandler,
    componentProps: { api },
    product: api,
  })),
];

export default routeConfig(routes);
