import React, { useMemo, useCallback } from "react";
import { filter } from "rxjs/operators";
import { SOCKET_EVENTS } from "../../../../../../../../constants/socketEvents";

import { Button, Input, Select } from "semantic-ui-react";
import {
  APPLICANT_STATUSES,
  PAYMENT_STATUSES,
  ZENDESK_VIEW_PAYMENT_STATUSES,
} from "../../../constants/applicantStatuses";
import toastr from "toastr"; 
import API_TABLE_DATA from "../../../constants/tableData";
import { useCallbackRef } from "../../../../../../../../hooks/useCallbackRef.hook.js";
import {
  useFilters,
  notEmptyValidator,
} from "../../../../../../../../hooks/useFilters.hook.js"; 
import { getOptionsFromObject } from "../../../../../../../../common/select-helper.js";
import { useSubscription } from "../../../../../../../../hooks/useSubscription.hook.js";
import socketService from "../../../../../../../../services/socketService.js"; 
import ApplicantsTable from "../../../components/ApplicantsTable"; 

const PassportApplicantTable = ({ api }) => { 
  const zendeskView = window.location.href.includes(`zendesk/${api}`);
  const [applicantsTable, applicantsTableRef] = useCallbackRef();
  const { filters, normalizedFilters, setFormFilter } = useFilters(
    {
      searchString: "", 
      status: [],
    },
    {
      localStorageKey: `${api}-passport-table`,
      filterValidators: {
        searchString: notEmptyValidator(),
      },
      filterDebounces: {
        searchString: 700,
      },
      temporaryFilters: ["searchString"],
    },
  );
  filters.status = [APPLICANT_STATUSES['Ready for PKO']];

  console.log(filters);

  const filtersRendered = useMemo(() => {
    if (!applicantsTable) {
      return null;
    }
    const { searchString, paymentStatus } = filters;
    return (
      <div className="filters-container"> 
        <Select
          placeholder="Select payment status"
          options={getOptionsFromObject(
            zendeskView ? ZENDESK_VIEW_PAYMENT_STATUSES : PAYMENT_STATUSES,
            {
              optionTextFrom: "key",
              optionValueFrom: "value",
            },
          )}
          multiple={!zendeskView}
          name="paymentStatus"
          value={paymentStatus === undefined ? [] : paymentStatus}
          onChange={setFormFilter}
        />
        <Input
          value={searchString || ""}
          type="text"
          name="searchString"
          placeholder="Search"
          onChange={setFormFilter}
        />
        <Button onClick={applicantsTable.refreshItems}>
          {applicantsTable.isLoading ? "Loading.." : "Refresh"}
        </Button>
      </div>
    );
  }, [applicantsTable, filters, api, setFormFilter, zendeskView]);

  useSubscription(
    socketService.socketSubject,
    useCallback(
      (event) => {
        if (!applicantsTable) {
          return;
        }
        const { setApplicants } = applicantsTable;
        const {
          data: { applicantId, botError },
        } = event;

        setApplicants((applicants) =>
          applicants
            ? applicants.map((applicant) =>
                applicant._id !== applicantId
                  ? applicant
                  : {
                      ...applicant,
                      errors: [botError, ...applicant.errors],
                    },
              )
            : null,
        );
        toastr.error("Bot returned error!", "Error");
      },
      [applicantsTable],
    ),
    [filter((event) => event.type === SOCKET_EVENTS[api].BOT_ERROR)],
  );
  
  const additionalActions = useCallback(() => {
    return [];
  }, []);

  const tableColumns = useMemo(() => {
    return zendeskView
      ? Object.fromEntries(
          Object.entries(API_TABLE_DATA[api].columns).filter(
            ([key]) =>
              ![
                "applicationType",
                "createdAt",
                ...(!Array.isArray(filters.paymentStatus)
                  ? ["paymentStatus"]
                  : []),
              ].includes(key),
          ),
        )
      : API_TABLE_DATA[api].columns;
  }, [api, filters.paymentStatus, zendeskView]);

  return (
    <ApplicantsTable
      api={api}
      normalizedFilters={normalizedFilters}
      filtersRendered={filtersRendered}
      ref={applicantsTableRef}
      tableColumns={tableColumns}
      additionalActions={additionalActions}
      zendeskView={zendeskView}
      isPassportPkoReady={true}
    />
  );
};

export default PassportApplicantTable;
