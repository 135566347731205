import React from "react";
import "./ModalButton.styles.scss";
import { Modal, Button, Icon } from "semantic-ui-react";

class ModalButton extends React.Component {
  state = { modalOpen: false };
  closeModal = () => {
    this.setState({ modalOpen: false });
  };
  openModal(event) {
    event.stopPropagation();
    this.setState({ modalOpen: true });
  }
  cloneChild = () => {
    const clonedChild = React.cloneElement(
      this.props.modalComponent,
      { closeModal: this.closeModal },
      null,
    );
    return clonedChild;
  };
  render() {
    const {
      buttonClassName,
      buttonIcon,
      buttonProps = {},
      modalStyle = {},
      closeButton,
      disabled = false,
    } = this.props;

    return (
      <Modal
        open={this.state.modalOpen}
        className="modal-container"
        style={modalStyle}
        onClose={this.closeModal}
        trigger={
          <Button
            disabled={disabled}
            className={buttonClassName}
            onClick={(event) => this.openModal(event)}
            {...buttonProps}
          >
            <Icon aria-hidden="true" className={`${buttonIcon} icon`}></Icon>
          </Button>
        }
      >
        {this.cloneChild()}
        {closeButton && (
          <Modal.Actions
            style={{
              justifyContent: "flex-end",
              backgroundColor: "transparent",
            }}
          >
            <Button color="grey" onClick={this.closeModal}>
              Close
            </Button>
          </Modal.Actions>
        )}
      </Modal>
    );
  }
}

export default ModalButton;
