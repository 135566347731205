import React from "react";

import { TRAVELASSIST_ZENDESK_TICKET_ENDPOINT } from "../../../../../../../constants/endpoints";
import { getDateFormat } from "../../../../../../../common/date-format";
import { API_NAMES } from "../../EVisa/constants/apiNames";
import { capitalizeEachWord } from "../../../../../../../utils";
import { Checkbox } from "semantic-ui-react";
import env from "../../../../../../../env";

export const columns = {
  checkBox: {
    label: "checkbox",
    value: (applicant, selectedApplicants, setSelectedApplicants) => (
      <Checkbox
        disabled={
          selectedApplicants.length >= env.PASSPORT_GENERATE_MAX_COUNT &&
          !selectedApplicants.includes(applicant._id)
        }
        checked={selectedApplicants.includes(applicant._id)}
        onChange={() => {
          if (selectedApplicants.includes(applicant._id)) {
            setSelectedApplicants(
              selectedApplicants.filter((id) => id !== applicant._id),
            );
          } else {
            setSelectedApplicants([...selectedApplicants, applicant._id]);
          }
        }}
      />
    ),
  },
  fullName: {
    label: "Name",
    value: (applicant) =>
      capitalizeEachWord(
        `${applicant.firstName || ""} ${applicant.lastName || ""}`,
      ),
  },
  userEmail: {
    label: "Email",
  },
  _id: {
    label: "ID",
    value: (applicant, zendeskView) => {
      return (
        <a
          href={`/${API_NAMES.DS82}/applicant/${applicant._id}`}
          target={zendeskView ? "" : "_blank"}
          rel="noopener noreferrer"
        >
          {applicant._id}
        </a>
      );
    },
  },
  applicationType: {
    label: "Type",
  },
  createdAt: {
    label: "Created at",
    value: (applicant) => getDateFormat(applicant.createdAt),
  },
  paymentStatus: {
    label: "Payment status",
  },
  status: {
    label: "Status",
  },
  siteId: {
    label: "Site ID",
    value: (applicant) => applicant.siteId,
  },
  zendeskId: {
    label: "Zendesk ID",
    value: (applicant) => (
      <a
        href={TRAVELASSIST_ZENDESK_TICKET_ENDPOINT(applicant.zendeskId)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {applicant.zendeskId}
      </a>
    ),
  },
};
