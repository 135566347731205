export default {
  SHOWN_PRODUCTS: (process.env.REACT_APP_SHOWN_PRODUCTS || "").split(","),
  API_URL: process.env.REACT_APP_API_URL,
  USPS_URL: process.env.REACT_APP_USPS_URL,
  PASSPORTS_SELECT_PORTRAIT:
    process.env.REACT_APP_PASSPORTS_SELECT_PORTRAIT === "true",
  PASSPORT_GENERATE_MAX_COUNT:
    process.env.REACT_APP_PASSPORT_GENERATE_MAX_COUNT || 8,
  PASSPORT_PORTAIT_REQUEST_INTERVAL:
    process.env.REACT_APP_PASSPORT_PORTAIT_REQUEST_INTERVAL || 60, // in minutes
};
